module.exports = {
  ServicesData: [
    {
      id: "business-services",
      icon: "/assets/images/app_landing1/icons/1-1.png",
      title: "Business Services",
      short:
        "Nidik support various business services for Information technology (IT) is an important business service that supports many other business...",
      details: `Nidik support various business services for Information technology (IT) is an important business service that supports many other business services such as procurement, shipping and finance. The service sector, also known as the tertiary sector, is the third tier in the three-sector economy. Instead of product production, this sector produces services maintenance and repairs, training, or consulting. Educational, Medical, Hospitality, and banking are the services included in service businesses. Bank service is the nerve center of industry and commerce in a country.`,
      bannerImage: "/assets/images/services/business-services.png",
      line1: `Business services are a recognisable subset of economic services, and share their characteristics. The essential difference is that businesses are concerned about the building of service systems in order to deliver value to their customers and to act in the roles of service provider and service consumer.`,
      line2: ``,
      children: [
        {
          id: "product-engineering",
          icon: "/assets/images/app_landing1/icons/1-1.png",
          title: "Product Engineering",
          short:
            "Product engineering refers to the process of designing and developing a device, assembly, or system such that it be produced as an item for sale through some product manufacturing process....",
          details:
            "Product engineering refers to the process of designing and developing a device, assembly, or system such that it be produced as an item for sale through some product manufacturing process. Product engineering usually entails activity dealing with issues of cost, producibility, quality, performance, reliability, serviceability, intended lifespan and user features. These product characteristics are generally all sought in the attempt to make the resulting product attractive to its intended market and a successful contributor to the business of the organization that intends to offer the product to that market. It includes design, development and transitioning to manufacturing of the product. The term encompasses developing the concept of the product and the design and development of its mechanical, electronics and software components. After the initial design and development is done, transitioning the product to manufacture it in volumes is considered part of product engineering.",
          bannerImage: "/assets/images/services/product-engineering.jpg",
          line1: ``,
          line2: ``,
          children: [],
        },
        {
          id: "application-integration",
          icon: "/assets/images/app_landing1/icons/1-1.png",
          title: "Application Integration",
          short:
            "Application integration is the process of enabling independently designed applications to work together...",
          details: `Application integration is the process of enabling individual applications—each designed for its own specific purpose—to work with one another. By merging and optimizing data and workflows between multiple software applications, organizations can achieve integrations that modernize their infrastructures and support agile business operations.`,
          bannerImage: "/assets/images/services/application-integration.png",
          line1: `Application integration helps bridge the gap between existing on-premises systems and fast-evolving cloud-based enterprise applications. Through seamlessly interconnected processes and data exchanges, application integration allows enterprises to orchestrate a variety of functions across their entire infrastructures, enabling businesses to operate more effectively and efficiently.`,
          line2: ``,
          children: [],
        },
        {
          id: "website-design-and-development",
          icon: "/assets/images/app_landing1/icons/1-1.png",
          title: "Website Design and Development",
          short:
            "Web design and development is an umbrella term that describes the process of creating a website...",
          details: `Web design and development is an umbrella term that describes the process of creating a website. Like the name suggests, it involves two major skill sets: web design and web development. Web design determines the look and feel of a website, while web development determines how it functions.`,
          bannerImage:
            "/assets/images/services/website-desgin-and-development.jpg",
          line1: `Details`,
          line2: `Details`,
          children: [],
        },
        {
          id: "application-development",
          icon: "/assets/images/app_landing1/icons/1-1.png",
          title: "Application Development",
          short:
            "Application development is the process of designing, building, and implementing software applications...",
          details: `Application development is the process of designing, building, and implementing software applications. It can be done by massive organizations with large teams working on projects, or by a single freelance developer.`,
          bannerImage: "/assets/images/services/application-development.png",
          line1: `Application development is the process of creating a computer program or a set of programs to perform the different tasks that a business requires. From calculating monthly expenses to scheduling sales reports, applications help businesses automate processes and increase efficiency. Every app-building process follows the same steps: gathering requirements, designing prototypes, testing, implementation, and integration.`,
          line2: `Details`,
          children: [],
        },
        {
          id: "mobile-application-development",
          icon: "/assets/images/app_landing1/icons/1-1.png",
          title: "Mobile Application Development",
          short:
            "Application development is the process of designing, building, and implementing software applications. It can be done by massive organizations with large teams working on projects, or by a single freelance developer...",
          details: `Application development is the process of designing, building, and implementing software applications. It can be done by massive organizations with large teams working on projects, or by a single freelance developer.`,
          bannerImage:
            "/assets/images/services/mobile-application-development.jpg",
          line1: `Application development is the process of creating a computer program or a set of programs to perform the different tasks that a business requires. From calculating monthly expenses to scheduling sales reports, applications help businesses automate processes and increase efficiency. Every app-building process follows the same steps: gathering requirements, designing prototypes, testing, implementation, and integration.`,
          line2: ``,
          children: [],
        },
        {
          id: "quality-assurance-and-testing",
          icon: "/assets/images/app_landing1/icons/1-1.png",
          title: "Quality Assurance and Testing",
          short:
            "Quality assurance (QA) testing is the process of ensuring that your product is of the highest possible quality for your customers....",
          details: `Quality assurance (QA) testing is the process of ensuring that your product is of the highest possible quality for your customers. QA is simply the techniques used to prevent issues with your software product or service and to ensure great user experience for your customers.`,
          bannerImage:
            "/assets/images/services/quality-assurance-and-testing.jpg",
          line1: `System testing begins at the module level and proceeds towards the integration of the entire software system. Different testing techniques are used at different times while testing the system. It is conducted by the developer for small projects and by independent testing groups for large projects.`,
          line2: ``,
          children: [],
        },
      ],
    },
    {
      id: "digital-services",
      icon: "/assets/images/app_landing1/icons/1-2.png",
      title: "Digital Service",
      short:
        "Nidik provide various kinds of digital services to client which helps to grow their business...",
      details: `Nidik provide various kinds of digital services to client which helps to grow their business. The term Digital Services refers to the electronic delivery of information including data and content across multiple platforms and devices like web or mobile. Digital services include a large category of online services, from simple websites to internet infrastructure services and online platforms.`,
      bannerImage: "/assets/images/services/digital-services.jpg",
      line1: ``,
      line2: ``,
      children: [
        {
          id: "digital-marketing",
          icon: "/assets/images/app_landing1/icons/1-1.png",
          title: "Digital Marketing",
          short:
            "Digital marketing is the component of marketing that uses the Internet and online based digital technologies...",
          details: `Digital marketing is the component of marketing that uses the Internet and online based digital technologies such as desktop computers, mobile phones and other digital media and platforms to promote products and services. Its development during the 1990s and 2000s changed the way brands and businesses use technology for marketing. As digital platforms became increasingly incorporated into marketing plans and everyday life, and as people increasingly use digital devices instead of visiting physical shops, digital marketing campaigns have become prevalent, employing combinations of search engine optimization (SEO), search engine marketing (SEM), content marketing, influencer marketing, content automation, campaign marketing, data-driven marketing, e-commerce marketing, social media marketing, social media optimization, e-mail direct marketing, display advertising, e–books, and optical disks and games have become commonplace. Digital marketing extends to non-Internet channels that provide digital media, such as television, mobile phones (SMS and MMS), callback, and on-hold mobile ring tones. The extension to non-Internet channels differentiates digital marketing from online advertising.`,
          bannerImage: "/assets/images/services/digital-marketing.jpg",
          line1: ``,
          line2: ``,
          children: [],
        },
        {
          id: "data-analytics",
          icon: "/assets/images/app_landing1/icons/1-1.png",
          title: "Data Analytics",
          short:
            "Analytics is the systematic computational analysis of data or statistics...",
          details: `Analytics is the systematic computational analysis of data or statistics. It is used for the discovery, interpretation, and communication of meaningful patterns in data. It also entails applying data patterns toward effective decision-making. It can be valuable in areas rich with recorded information; analytics relies on the simultaneous application of statistics, computer programming, and operations research to quantify performance.`,
          bannerImage: "/assets/images/services/data-analytics.jpg",
          line1: `Organizations may apply analytics to business data to describe, predict, and improve business performance. Specifically, areas within analytics include descriptive analytics, diagnostic analytics, predictive analytics, prescriptive analytics, and cognitive analytics. Analytics may apply to a variety of fields such as marketing, management, finance, online systems, information security, and software services. Since analytics can require extensive computation (see big data), the algorithms and software used for analytics harness the most current methods in computer science, statistics, and mathematics.`,
          line2: ``,
          children: [],
        },
        {
          id: "internet-of-things",
          icon: "/assets/images/app_landing1/icons/1-1.png",
          title: "Internet Of Things",
          short:
            "The Internet of things describes physical objects with sensors, processing ability, software, and other...",
          details: `The Internet of things (IoT) describes physical objects (or groups of such objects) with sensors, processing ability, software, and other technologies that connect and exchange data with other devices and systems over the Internet or other communications networks. Internet of things has been considered a misnomer because devices do not need to be connected to the public internet, they only need to be connected to a network and be individually addressable.`,
          bannerImage: "/assets/images/services/internet-of-things.jpeg",
          line1: `The field has evolved due to the convergence of multiple technologies, including ubiquitous computing, commodity sensors, increasingly powerful embedded systems, and machine learning. Traditional fields of embedded systems, wireless sensor networks, control systems, automation (including home and building automation), independently and collectively enable the Internet of things. In the consumer market, IoT technology is most synonymous with products pertaining to the concept of the "smart home", including devices and appliances (such as lighting fixtures, thermostats, home security systems, cameras, and other home appliances) that support one or more common ecosystems, and can be controlled via devices associated with that ecosystem, such as smartphones and smart speakers. IoT is also used in healthcare systems.`,
          line2: ``,
          children: [],
        },
        {
          id: "mobility",
          icon: "/assets/images/app_landing1/icons/1-1.png",
          title: "Mobility",
          short:
            "Mobility, in its broadest definition, is that smart and connected technologies can eliminate the friction in business processes and no doubt is the key enabler of digital transformation...",
          details: `Mobility, in its broadest definition, is that smart and connected technologies can eliminate the friction in business processes and no doubt is the key enabler of digital transformation. It is evident that Mobility has emerged as a business-critical platform that is driving digital transformation.`,
          bannerImage: "/assets/images/services/mobility.png",
          line1: `Enterprise mobility is an approach to work in which employees can do their jobs from anywhere using a variety of devices and applications.`,
          line2: `Details`,
          children: [],
        },
        {
          id: "ui-ux-design",
          icon: "/assets/images/app_landing1/icons/1-1.png",
          title: "UI / UX Design",
          short:
            "A user experience (UX) designer works on a team to create products that provide meaningful and enjoyable experiences for users...",
          details: `Details`,
          bannerImage: "/assets/images/services/ui-ux-design.png",
          line1: `A user experience (UX) designer works on a team to create products that provide meaningful and enjoyable experiences for users. They are concerned with the entire process of product design, from branding to design to useability. UI (user interface) designers build interfaces in software or other computerized devices.`,
          line2: ``,
          children: [],
        },
        {
          id: "cloud-computing",
          icon: "/assets/images/app_landing1/icons/1-3.png",
          title: "Cloud Computing",
          short:
            "Cloud computing is the delivery of different services through the Internet, including data storage, servers, databases, networking, and software...",
          details: `Cloud computing is the on-demand availability of computer system resources, especially data storage (cloud storage) and computing power, without direct active management by the user. Large clouds often have functions distributed over multiple locations, each location being a data center. Cloud computing relies on sharing of resources to achieve coherence and typically using a "pay-as-you-go" model which can help in reducing capital expenses but may also lead to unexpected operating expenses for unaware users.`,
          bannerImage: "/assets/images/services/cloud-computing.jpg",
          line1: `Agility for organizations may be improved, as cloud computing may increase users' flexibility with re-provisioning, adding, or expanding technological infrastructure resources.`,
          line2: `Details`,
          children: [],
        },
      ],
    },
  ],
};

import React from "react";
import { Container, Row, Col } from "reactstrap";
const Services = ({ data }) => (
  <section className="services" id="service">
    <Container>
      <Row>
        {data ? (
          <Col lg="6" md="8" className="offset-lg-3 offset-md-2">
            <div className="title">
              <div className="main-title">
                <h2>{data.title}</h2>
              </div>
              <div className="sub-title">
                <p className="para">
                  GET OUR INNOVATIVE SERVICES FOR BUILDING YOUR BUSINESS
                  SOLUTION
                </p>
              </div>
            </div>
          </Col>
        ) : (
          <Col lg="6" md="8" className="offset-lg-3 offset-md-2">
            <div className="title">
              <div className="main-title">
                <h2>Our Services</h2>
              </div>
              <div className="sub-title">
                <p className="para">
                  GET OUR INNOVATIVE SERVICES FOR BUILDING YOUR BUSINESS
                  SOLUTION
                </p>
              </div>
            </div>
          </Col>
        )}
        {data &&
          data.children.map((item, index) => {
            return (
              <>
                <Col md="4">
                  <div className="service service-overlay">
                    <div className="img-block">
                      <img
                        alt=""
                        className="service-img img-fluid"
                        src={item.icon}
                      />
                    </div>
                    <div className="service-feature">
                      <h4 className="feature-text">{item.title}</h4>
                      <p>{item.short}</p>
                    </div>
                  </div>
                </Col>
                {item.children.map((item, index) => {
                  return (
                    <Col md="4">
                      <div className="service service-overlay">
                        <div className="img-block">
                          <img
                            alt=""
                            className="service-img img-fluid"
                            src={item.icon}
                          />
                        </div>
                        <div className="service-feature">
                          <h4 className="feature-text">{item.title}</h4>
                          <p>{item.short}</p>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </>
            );
          })}
      </Row>
    </Container>
  </section>
);

export default Services;
